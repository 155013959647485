import { FC, useContext, useEffect, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { ConfiguratorContext } from "../contexts/ConfiguratorContext";
import { IImageSize } from "../model/CustomizerData";
import { GenerateUrl } from "../helpers/RenderHelper";
import Spinner from "./Spinner";
import Hotspots from "./Hotspots";
import './Visualization.scss';

export const Visualization: FC = () => {

    const { currentConfiguration } = useContext(ConfiguratorContext); 
    const [topSrc, setTopSrc] = useState("");
    const [bottomSrc, setBottomSrc] = useState("");
    const [loading, setLoading] = useState(false);
    const [imageSize, setImageSize] = useState<IImageSize | null>(null);

    var imgRef = useRef<HTMLImageElement>(null);   

    useEffect(() => {
        if (topSrc !== GenerateUrl(currentConfiguration, false)) {
            queueMicrotask(() => {
                flushSync(() => {
                    setLoading(true);
                    setBottomSrc(topSrc);
                    setTopSrc('');
                });
                setTopSrc(GenerateUrl(currentConfiguration, false));
            });
        }

    }, [currentConfiguration]);

    return <div className="visualization">
        <Spinner visible={loading} />
        {bottomSrc &&
            <img src={bottomSrc} key={bottomSrc} alt='' />
        }
        {topSrc &&
            <img
                key={topSrc}
                className={loading ? 'loading' : ''}
                alt=''
                ref={imgRef}
                src={topSrc}
                onLoad={(e) => {
                    setLoading(false);
                    setImageSize({ width: imgRef.current?.naturalWidth || 0, height: imgRef.current?.naturalHeight || 0 });
                }}
                onError={(e) => setLoading(false)}
            />
        }
        <Hotspots imageSize={imageSize}></Hotspots>
    </div>
}

export default Visualization;
