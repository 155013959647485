import { FunctionComponent, useContext, useEffect, useState, useRef, MouseEvent, useCallback } from "react";
import { ConfiguratorContext } from "../contexts/ConfiguratorContext";
import { DataContext } from "../contexts/DataContext";
import { ISize, IImageSize, IHotspot } from "../model/CustomizerData";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IConfigurableRef } from "../model/CustomizerData";
import "./Hotspots.scss";
import { useDebouncedCallback } from "use-debounce";

interface HotspotsProps {
    imageSize: IImageSize | null;
}

const Hotspots: FunctionComponent<HotspotsProps> = (props: HotspotsProps) => {

    const { scene } = useContext(DataContext);
    const { currentConfiguration, open, changeOpen, selectedConfigurable, changeSelectedConfigurable, changeSelectedOption, changeShowPreset } = useContext(ConfiguratorContext);
    const [size, setSize] = useState<ISize | null>({ windowAspectRatio: 0, imageAspectRatio: 0, imageWidth: 0, imageHeight: 0 });
    const [isMouseMoving, setIsMouseMoving] = useState(false);

    const labelRef = useRef(null);

    const hideHotspots = () => {
        setIsMouseMoving(false);
    }
    const debouncedHide = useDebouncedCallback(hideHotspots, 750);

    const showHotspots = useCallback(() => {        
        setIsMouseMoving(true);
        debouncedHide();      
    }, [debouncedHide]);

    useEffect(() => {

        function handleResize() {

            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const windowAspectRatio = windowWidth / windowHeight;

            const imageWidth = props.imageSize?.width || 0;
            const imageHeight = props.imageSize?.height || 0;
            const imageAspectRatio = imageWidth / imageHeight;

            setSize({ windowAspectRatio: windowAspectRatio, imageAspectRatio: imageAspectRatio, imageWidth: imageWidth, imageHeight: imageHeight });            

        }

        window.addEventListener("resize", handleResize);
        handleResize();

       // window.addEventListener("mousemove", showHotspots)

        return () => {
            window.removeEventListener("resize", handleResize)
        };
    }, [props.imageSize, showHotspots]);   

  /*  useEffect(() => {

        function resize() {

            imgWidth = 1920;
            imgHeight = 1080;
            windowWidth = window.innerWidth;
            windowHeight = window.innerHeight;

            //var hotspots = document.querySelectorAll(".hotspot") as NodeListOf<HTMLAnchorElement>;
            hotspotsRef.current.forEach(hotspot => {                
          
                if (hotspot) {

                    let topOffsetPct = parseFloat(hotspot.getAttribute("data-top") ?? "0") / 100;
                    let leftOffsetPct = parseFloat(hotspot.getAttribute("data-left") ?? "0") / 100;
            
                    var imgWidthAspect = windowWidth / imgWidth;
                    var imgHeightAspect = windowHeight / imgHeight;

                    topOffset =  (windowHeight - (imgWidthAspect*imgHeight))/2;
                    topOffset = (topOffset > 0) ? topOffset : 0; 
                    if (imgHeightAspect < imgWidthAspect) {
                        imgWidthAspect = imgHeightAspect;
                    }
                    topOffset +=  (imgWidthAspect*(imgHeight * topOffsetPct));    

                    leftOffset =  (windowWidth - (imgHeightAspect*imgWidth))/2;
                    leftOffset = (leftOffset > 0) ? leftOffset : 0;  
                    if (imgWidthAspect < imgHeightAspect) {
                        imgHeightAspect = imgWidthAspect;
                    }
                    leftOffset += (imgHeightAspect*(imgWidth * leftOffsetPct));
                 
                    hotspot.style.position = "absolute";                                    
                    hotspot.style.top = topOffset+"px";
                    hotspot.style.left = leftOffset+"px";                         
                   

                }
            });

        }

        window.addEventListener("resize", resize);
        resize();
        return () => window.removeEventListener("resize", resize);

    })*/   

   const setStyle = (top: number, left: number) => {

        if (size) {
            if (size.windowAspectRatio > size.imageAspectRatio) {
                top = (top / size.imageHeight) * 100;
                left = (left / size.imageWidth) * 100;
            } else {
                top = ((top / (size.windowAspectRatio / size.imageAspectRatio)) / size.imageHeight) * 100;
                left = ((left / (size.windowAspectRatio / size.imageAspectRatio)) / size.imageWidth) * 100;
            }
        }

        return { marginTop: top + "%", marginLeft: left + "%" };

    }

    const showLabel = (e: MouseEvent, configurableID: string) => {

        if (labelRef.current) {
            var hotspotElem = e.currentTarget as HTMLAnchorElement;
            var labelElem = labelRef.current as HTMLDivElement;

            var hotSpotTop = hotspotElem.offsetTop;
            var hotSpotLeft = hotspotElem.offsetLeft;
            var hotSpotHalfSize = hotspotElem.clientWidth / 2;
            var labelHalfSize = labelElem.clientWidth / 2;

            var topTarget = hotSpotTop - labelElem.clientHeight;
            var leftTarget = (hotSpotLeft - (labelHalfSize)) + hotSpotHalfSize;

            var configurable = scene.configurables.find(e => { return e.id === configurableID });

            labelElem.innerHTML = configurable?.name || "";

            labelElem.style.top = (topTarget - 20).toString() + "px";
            labelElem.style.left = leftTarget.toString() + "px";
            labelElem.style.display = "block";
            labelElem.style.opacity = "1";
        }
    }

    const hideLabel = () => {
        if (labelRef.current) {
            var labelElem = labelRef.current as HTMLDivElement;
            labelElem.style.opacity = "0";
        }
    }

    const openConfigurable = (id: string) => {

        changeOpen(!(open && selectedConfigurable?.id === id));
        var c = scene.configurables.find(e => { return e.id === id }) as IConfigurableRef;
        changeSelectedConfigurable(c);
        changeSelectedOption(null);
        changeShowPreset(false);

    }

    const showHotspot = (hotspot: IHotspot) => {
       
        if (currentConfiguration.angle === "closeup" || currentConfiguration.angle === "mood") {
            let configurableBottom = currentConfiguration.options.find(e => e.configurable.id == "frontbottom");
            if (configurableBottom) {
                if (hotspot.configurableID === "handle") {
                    return !configurableBottom.option.handleless ? <a key={hotspot.id} className="hotspot" id={hotspot.configurableID} style={setStyle(hotspot.top, hotspot.left)} onMouseOver={(e) => showLabel(e, hotspot.configurableID)} onMouseOut={(e) => hideLabel()} onClick={() => openConfigurable(hotspot.configurableID)}><FontAwesomeIcon icon={["fas", "pencil"]} /></a> : null
                }
            }  
        }
        
        let configurable = currentConfiguration.options.find(e => e.configurable.id == "front");
        if (configurable) {
            if (hotspot.configurableID === "handle") {
                return !configurable.option.handleless ? <a key={hotspot.id} className="hotspot" id={hotspot.configurableID} style={setStyle(hotspot.top, hotspot.left)} onMouseOver={(e) => showLabel(e, hotspot.configurableID)} onMouseOut={(e) => hideLabel()} onClick={() => openConfigurable(hotspot.configurableID)}><FontAwesomeIcon icon={["fas", "pencil"]} /></a> : null
            }
        } 
       
        return <a key={hotspot.id} className="hotspot" data-top={hotspot.top} data-left={hotspot.left} id={hotspot.configurableID} style={setStyle(hotspot.top, hotspot.left)} onMouseOver={(e) => showLabel(e, hotspot.configurableID)} onMouseOut={(e) => hideLabel()} onClick={() => openConfigurable(hotspot.configurableID)}><FontAwesomeIcon icon={["fas", "pencil"]} /></a>
        
    }

    return (
        <div className={`hotspots ${isMouseMoving ? 'show': ''}`} onMouseMove={showHotspots}>
            {scene.angles.find(e => e.id === currentConfiguration.angle)?.hotspots.map((f,idx) => {
                return showHotspot(f)
            })}
            <div ref={labelRef} className="hotspot-label"></div>
        </div>
    )

}

export default Hotspots;