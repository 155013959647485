import { pdf, Page, Text, Image, View, Document, StyleSheet, Link, Font } from '@react-pdf/renderer';
import { GenerateAngleUrl, GenerateConfigUrl } from "./RenderHelper";
import { IConfiguration, IConfigurationOption } from "../model/Configuration";
import { calculateTotalPrice } from "./PriceHelper";

import hobokenFont from "../fonts/hoboken-serial-medium-regular-webfont.woff";
import calibreFont from "../fonts/CalibreWeb-Regular.woff";
import calibreFontBold from "../fonts/CalibreWeb-Medium.woff";
import { IScene } from '../model/CustomizerData';

const numberFormat = (value: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'decimal',   
    maximumFractionDigits: 0   
  }).format(value);

Font.register({
  family: 'Hoboken',
  fonts: [{
    src: hobokenFont
  }]
});

Font.register({
  family: 'Calibre',
  fonts:
    [
      {
        src: calibreFont,
        fontWeight: 400
      },
      {
        src: calibreFontBold,
        fontWeight: 500
      },
    ]
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#778077',
    color: '#FFFFFF',
    fontFamily: 'Calibre'
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  logoWrapper: {
    flex: '0 1 18%'
  },
  logo: {
    width: '100%',
    height: 'auto'
  },
  linkWrapper: {
    flex: '1 0 auto'
  },
  link: {
    color: '#FFFFFF',
    fontSize: '12px'
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  firstInnerContainer: {
    flex: '0 1 66.66%'
  },
  firstImage: {
    width: '100%',
    height: 'auto'
  },
  secondInnerContainer: {
    flex: '0 1 33.33%',
    flexWrap: 'wrap'

  },
  secondaryTopImage: {
    marginLeft: '1%',
    marginBottom: '1%',
    width: '99%',
    height: 'auto'
  },
  secondaryBottomImage: {
    marginLeft: '1%',
    width: '99%',
    height: 'auto'
  },
  textContainer: {
    padding: '40px',
    flex: '1 0 auto'
  },
  headline: {
    marginBottom: '20px',
    fontFamily: 'Hoboken',
    fontSize: '18px',
    fontWeight: 500
  },
  configContainer: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'row'
  },
  configDataWrapper: {
    flex: '0 1 80%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',  
    height: '120px'
  },
  configData: {
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: "12px"
  },
  configDataConfigurable: {
    flex: '0 1 12%',    
    fontWeight: 500
  },
  configDataOption: {
    flex: '0 1 35%',
    fontWeight: 400
  },
  priceData: {
    flex: '0 1 30%',
  },
  priceWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  priceLabel: {
    fontSize: '12px',
    textTransform: "uppercase"
  },
  priceDesc: {
    marginTop: '10px',
    fontSize: '11px',
    width: '80%'   
  },
  price: {
    position: 'relative',
    top: '-4px',
    marginLeft: '10px',
    fontSize: '24px'
  }

});

const renderConfigData = (configOption: IConfigurationOption) => {
  switch (configOption.configurable.id) {
    case "front":
    case "frontbottom": {
      return <View key={configOption.option.name}>
        <View style={styles.configData}>
          <Text style={styles.configDataConfigurable}>{configOption.configurable.name}</Text><Text style={styles.configDataOption}>{configOption.option.name} - {configOption.color?.name}</Text>
        </View>      
      </View>
    }
    default: {
      return <View key={configOption.option.name}>
        <View style={styles.configData}>
          <Text style={styles.configDataConfigurable}>{configOption.configurable.name}</Text><Text style={styles.configDataOption}>{configOption.option.name}</Text>
        </View>
      </View>
    }
  }
}

const renderSimpleData  = (data: any) => {
  return <View key={data.value}>
  <View style={styles.configData}>
    <Text style={styles.configDataConfigurable}>{data.label}</Text><Text style={styles.configDataOption}>{data.value}</Text>
  </View>
</View>
}

const PDFDocument = (scene: IScene, configuration: IConfiguration) => {

  //let totalPrice = numberFormat(calculateTotalPrice(scene, configuration)).replace(",",".");

  let frontOption = configuration.options.find(e => e.configurable.id === "front");  
  if (frontOption?.option.handleless) {
    let handleOption = configuration.options.find(e => e.configurable.id === "handle");  
    if (handleOption) {
      let index = configuration.options.indexOf(handleOption);
      configuration.options.splice(index,1);
    }
  }
                   
  return <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={styles.imageContainer}>
        <View style={styles.firstInnerContainer}>
          <Image style={styles.firstImage} source={GenerateAngleUrl(configuration, 'main', false)} />
        </View>
        <View style={styles.secondInnerContainer}>
          <Image style={styles.secondaryTopImage} source={GenerateAngleUrl(configuration, 'closeup', false)} />
          <Image style={styles.secondaryBottomImage} source={GenerateAngleUrl(configuration, 'mood', false)} />
        </View>
      </View>
      <View style={styles.textContainer}>
        <View><Text style={styles.headline}>Dine køkkendrømme</Text></View>
        <View style={styles.configContainer}>
          <View style={styles.configDataWrapper}>
            {configuration.options.filter(e => { return ['front','frontbottom','handle','handlebottom','tabletop'].includes(e.configurable.id) }).map(c => renderConfigData(c))}
            {configuration.options.filter(e => { return ['sink', 'tap', 'wall', 'floor'].includes(e.configurable.id) }).map(c => renderConfigData(c))}
          </View>          
        </View>
        <View style={styles.logoContainer}>
          <View style={styles.linkWrapper}><Link style={styles.link} src={GenerateConfigUrl(configuration)}><Text style={styles.link}>Klik her for at se din konfiguration</Text></Link></View>
          <View style={styles.logoWrapper}><Image style={styles.logo} source="/logo_white.png" /></View>
        </View>
      </View>
    </Page>
  </Document>
};

export const renderPDF = (scene: IScene, configuration: IConfiguration) => {
  var blob = pdf(PDFDocument(scene, configuration)).toBlob();
  return blob;
}