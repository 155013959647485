import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DataContext, DataProvider } from './contexts/DataContext';
import Spinner from './components/Spinner';
//import ReactGA from 'react-ga4';
//import TagManager from 'react-gtm-module';
//import "./i18n"

/*const TRACKING_ID = "G-17SQJL6S65";
ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = {
  gtmId: 'GTM-5WQ6FTZ'
}
TagManager.initialize(tagManagerArgs);*/

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <React.Suspense fallback={<Spinner visible={true} />}>
          <DataProvider>
              <DataContext.Consumer >
                  {(context) => context.loading ? <Spinner visible={true} /> : <App />}
              </DataContext.Consumer>
          </DataProvider>
      </React.Suspense>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
