import { FC, useState } from "react";
import ActionMenu from "./ActionMenu";
import AngleMenu from "./AngleMenu";
import Menu from "./Menu";
import { MobileTurn } from "./MobileTurn";
import { Modal } from "./Modal";
import Visualization from "./Visualization";
import { useCookies } from "react-cookie";

export const Configurator: FC = () => {

    const [showPresetModal, setShowPresetModal] = useState(true);
    const [cookies, setCookie, removeCookie] = useCookies(['welcome']);

    const onCloseModal = () => {
        setCookie("welcome", true);
        setShowPresetModal(false);
    }
    
    return <div>
        <Visualization />
        <Menu/>  
        <AngleMenu />
        <ActionMenu/>
        {!cookies["welcome"] && <Modal show={showPresetModal} header="Design dit nye køkken" description="<p>Der er masser af valgmuligheder, når det handler om køkkenindretning.
Her kan du lege med et udvalg af vores materialer og designs og blive klogere på, hvilket look og materialemix, du ønsker i dit nye køkken. Skab dit helt personlige køkkenlook med vores køkkendesigner.</p>" onCloseModal={() => onCloseModal()} />}
        <MobileTurn/>
    </div>
}

export default Configurator;