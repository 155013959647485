import { FC, useContext } from "react"
import { DataContext } from "../contexts/DataContext";
import { ConfiguratorContext } from "../contexts/ConfiguratorContext";
import { IAngle } from "../model/CustomizerData";
import "./AngleMenu.scss";

export const AngleMenu: FC = () => {
    const { currentConfiguration, changeAngle } = useContext(ConfiguratorContext);
    const { scene } = useContext(DataContext);  

    const changeSelectedAngle = (angle: string) => {
        changeAngle(angle);
    }

    const thumbnailImage = (angle: IAngle) => {
        return `api/thumbnail/angle/${angle.id}`;
    }

    return <div className="angle-menu">
        <ul>
        { scene.angles.map(a => <li key={a.id}><button className={ (currentConfiguration.angle === a.id) ? "selected" : "" } onClick={(e) => { changeSelectedAngle(a.id)}}><div className="menu-img-wrapper"><img src={thumbnailImage(a)} alt={a.name}/></div><span>{a.name}</span></button></li> )}
        </ul>       
    </div>

}

export default AngleMenu;