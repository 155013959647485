import * as React from 'react';
import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import './Spinner.scss';

interface SpinnerProps {
    visible: boolean;
}

const Spinner: FunctionComponent<SpinnerProps> = (props: SpinnerProps) => {
    var classNames = "loader";
    if (props.visible) {
        classNames += " show"
    }

    return (
        <div className={classNames}>
            <div className="loaderSpinner">
                <FontAwesomeIcon icon={faSpinner} spin={true} size='3x'></FontAwesomeIcon>
            </div>           
        </div>
    );
}

export default Spinner;
