import React, { createContext, useState, FC, useEffect } from "react";
import { IConfigurable, IScene } from "../model/CustomizerData";

export interface DataContextState {
    loading: boolean;
    options: IConfigurable[];
    scene: IScene;
};

const contextDefaultValues: DataContextState = {
    loading: false,
    options: [],
    scene: {
        angles: [],
        configurables: [],
        id: '',
        presets: [],
        nonconfigurables: []
    }
};

export const DataContext = createContext<DataContextState>(
    contextDefaultValues
);

export const DataProvider: FC<React.PropsWithChildren> = ({ children }) => {
    const [loading, setLoading] = useState(contextDefaultValues.loading);
    const [options, setOptions] = useState(contextDefaultValues.options);
    const [scene, setScene] = useState(contextDefaultValues.scene);

    useEffect(() => {
        setLoading(true);

        var options = fetch('/api/options')
            .then(res => res.json())
            .then(res => {
                setOptions(res);
            });

        var scene = fetch('/api/scenes/default')
            .then(res => res.json())
            .then(res => {
                setScene(res);
            });

        Promise.all([options, scene])
            .then(() => setLoading(false))
            .catch(err => console.log(err));
    }, []);

    return (
        <DataContext.Provider
            value={{
                loading,
                options,
                scene
            }}
        >
            {children}
        </DataContext.Provider>
    );
};