import React, { createContext, useState, FC, useEffect } from "react";
import { IConfiguration, IConfigurationOption } from "../model/Configuration";
import { IChangeable, IColor, IConfigurable, IConfigurableRef, IOption, IPreset, IScene } from "../model/CustomizerData";

export interface ConfiguratorContextState {
    currentConfiguration: IConfiguration;
    selectedConfigurable: IConfigurableRef | null,
    selectedOption: IOption | null,
    selectedPreset: IPreset | null,
    open: boolean;
    openSub: boolean,
    showPreset: boolean;
    changeCurrentConfiguration: (options: IConfigurationOption[]) => void;
    changeAngle: (angle: string) => void;
    changeSelectedConfigurable: (configurable: IConfigurableRef | null) => void;
    changeSelectedOption: (option: IOption | null) => void;
    changeSelectedPreset: (preset: IPreset | null) => void;
    changeOpen: (open: boolean) => void;
    changeOpenSub: (open: boolean) => void;
    changeOption: (configurable: IConfigurableRef, option: IOption | null, color?: IColor) => void;
    changeOptions: (changeables: IChangeable[]) => void;
    changeShowPreset: (show: boolean) => void;
    validatePreset: (configurable: IConfigurableRef, option: IOption | null, color?: IColor) => void;
    loadPreset: (preset: IPreset) => void;
};

const contextDefaultValues: ConfiguratorContextState = {
    currentConfiguration: {
        angle: '',
        options: [],
        scene: ''
    },
    selectedConfigurable: null,
    selectedOption: null,
    selectedPreset: null,
    open: false,
    openSub: false,
    showPreset: false,
    changeCurrentConfiguration: () => { },
    changeAngle: () => { },
    changeSelectedConfigurable: () => { },
    changeSelectedOption: () => { },
    changeSelectedPreset: () => { },
    changeOption: () => { },
    changeOptions: () => { },
    changeOpen: () => { },
    changeOpenSub: () => { },
    changeShowPreset: () => { },
    validatePreset: () => { },
    loadPreset: () => { }
};

export const ConfiguratorContext = createContext<ConfiguratorContextState>(
    contextDefaultValues
);

export const ConfiguratorProvider: FC<React.PropsWithChildren<{ options: IConfigurable[], scene: IScene }>> = ({ children, options, scene }) => {
    const [currentConfiguration, setCurrentConfiguration] = useState(contextDefaultValues.currentConfiguration);
    const [selectedConfigurable, setSelectedConfigurable] = useState(contextDefaultValues.selectedConfigurable);
    const [selectedOption, setSelectedOption] = useState(contextDefaultValues.selectedOption);
    const [open, setOpen] = useState(contextDefaultValues.open);
    const [openSub, setOpenSub] = useState(contextDefaultValues.openSub);
    const [showPreset, setShowPreset] = useState(contextDefaultValues.showPreset);
    const [selectedPreset, setSelectedPreset] = useState(contextDefaultValues.selectedPreset);

    useEffect(() => {
        if (options.length === 0 || scene.angles.length === 0) {
            return;
        }

        let defaultOptions: IConfigurationOption[] = [];

        for (let index = 0; index < scene.configurables.length; index++) {
            const configurable = scene.configurables[index];
            const o = options.find(e => e.id.toLowerCase() === configurable.optionsID.toLowerCase());
            let option = o?.options.find(e => e.id.toLowerCase() === configurable.defaultOption.toLowerCase());
            let color = option?.colors?.find(e => e.id === configurable.defaultColor?.toLowerCase());

            if (!option) {
                if (configurable.dependancy) {
                    let dependancyConfigurable = scene.configurables.find(x => x.id === configurable.dependancy)
                    const o = options.find(e => e.id.toLowerCase() === dependancyConfigurable?.optionsID.toLowerCase());
                    option = o?.options.find(e => e.id.toLowerCase() === dependancyConfigurable?.defaultOption.toLowerCase());
                    color = option?.colors?.find(e => e.id == dependancyConfigurable?.defaultColor?.toLowerCase());
                }
            }          

            if (option) {
                defaultOptions.push({ configurable: configurable, option: option, color: color });
            }
        }

        setOpen(false);
        setOpenSub(false);
        setShowPreset(false);
        setSelectedConfigurable(null);

        let config: IConfigurationOption[] = [];
        if (window.location.search) {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.forEach((value, key) => {

                const configurable = scene.configurables.find(e => e.id === key);
                if (configurable) {

                    let optionValue = (['front','frontbottom'].includes(configurable.id)) ? value.split("_")[0] : value;

                    const o = options.find(e => e.id == configurable?.optionsID);
                    let option = o?.options.find(e => e.id == optionValue);
                    let color = option?.colors?.find(e => e.id == value);

                    
                    if (!option) {
                        if (configurable.dependancy) {
                            let dependancyConfigurable = scene.configurables.find(x => x.id === configurable.dependancy);
                            const o = options.find(e => e.id.toLowerCase() === dependancyConfigurable?.optionsID.toLowerCase());
                            option = o?.options.find(e => e.id.toLowerCase() === optionValue);
                            color = option?.colors?.find(e => e.id == value);
                        }
                    }

                    if (option) {
                        config.push({ configurable: configurable, option: option, color: color });
                    }
                }
            });
        }

        setCurrentConfiguration({
            angle: scene.angles[0].id,
            options: (config.length > 0) ? config : defaultOptions,
            scene: scene.id
        });

    }, [options, scene]);

    const validatePreset = (configurable: IConfigurableRef, option: IOption | null, color?: IColor) => {

        if (option) {
            var preset = scene.presets.find(x => { return x.id === selectedPreset?.id });
            var presetOption = preset?.options.find(x => { return x.configurableId === configurable.id });
            if (presetOption) {
                if (color) {
                    if (presetOption?.option !== option.id || presetOption.color !== color.id) {
                        setSelectedPreset(null);
                    }
                } else {
                    if (presetOption?.option !== option.id) {
                        setSelectedPreset(null);
                    }
                }
            }
        }

    }

    const changeCurrentConfiguration = (options: IConfigurationOption[]) => {

        setCurrentConfiguration({ ...currentConfiguration, options: options });

    }

    const handleDisables = (option: IOption | null, configOptions:  IConfigurationOption[]) => {
        if (option) {
            option.disables?.forEach((value) => {
                switch (value) {
                    case "backsplash": {
                        const configRef = scene.configurables.find(x => x.id === value);
                        if (configRef) {
                            configOptions = configOptions.filter(e => e.configurable !== configRef);
                            const o = options.find(e => e.id == configRef.optionsID);
                            const disableOption = o?.options.find(e => e.id == "nobacksplash");
                            if (disableOption) {
                                configOptions.push({ configurable: configRef, option: disableOption });
                            }
                        }
                    }
                        break;
                }
            });
        }

        return configOptions;
    }

    const changeOption = (configurable: IConfigurableRef, option: IOption | null, color?: IColor) => {
        let configOptions = currentConfiguration.options.filter(e => e.configurable !== configurable);

        if (option) {
            configOptions.push({ configurable: configurable, option: option, color: color });
        }

        configOptions = handleDisables(option, configOptions);

        setCurrentConfiguration({ ...currentConfiguration, options: configOptions });

        validatePreset(configurable, option, color);
    }

    const handleHandles = (configOptions: IConfigurationOption[]) => {

        const handleOption = configOptions.find(e => e.configurable.id == "handle"); 
        const handleConfigurable = scene.configurables.find(e => e.id === "handle");       

        const handlebottomOption = configOptions.find(e => e.configurable.id == "handlebottom");
        const handlebottomonfigurable = scene.configurables.find(e => e.id === "handlebottom");

        const frontOption = configOptions.find(e => e.configurable.id == "front");
        const frontbottomOption = configOptions.find(e => e.configurable.id == "frontbottom");     

        //Front Handles
        if (frontOption && frontOption?.option?.handleless) {
            var option = configOptions.find(x => x.configurable.id === handleOption?.configurable.id);
            if (option) {               
                var index = configOptions.indexOf(option);
                configOptions.splice(index,1);
            }
        } else {
            if (!frontbottomOption?.option?.handleless) {
                if (handleConfigurable && handlebottomOption) {

                    configOptions = configOptions.filter(e => e.configurable !== handleConfigurable);
                    configOptions.push({ configurable: handleConfigurable, option: handlebottomOption?.option });                   
                }
            }
        }

        //Front Bottom Handles
        if (frontbottomOption && frontbottomOption?.option?.handleless) {
            var option = configOptions.find(x => x.configurable.id === handlebottomOption?.configurable.id);
            if (option) {               
                var index = configOptions.indexOf(option);
                configOptions.splice(index,1);
            }
        } else {
            if (!frontOption?.option?.handleless) {
                if (handlebottomonfigurable && handleOption) {

                    configOptions = configOptions.filter(e => e.configurable !== handlebottomonfigurable);
                    configOptions.push({ configurable: handlebottomonfigurable, option: handleOption?.option });                   
                }
            }
        }

        return configOptions;
        
    }

    const changeOptions = (changeables: IChangeable[]) => {

        let configOptions = currentConfiguration.options.filter(e => { return !changeables.map(x => x.configurable.id).includes(e.configurable.id)});
      
        changeables.forEach(changeable => {
            if (changeable.option) {
                configOptions.push({ configurable: changeable.configurable, option: changeable.option, color: changeable.color });
            }
            validatePreset(changeable.configurable, changeable.option, changeable.color);
        });

        configOptions = handleHandles(configOptions);

        setCurrentConfiguration({ ...currentConfiguration, options: configOptions });
    }

    const loadPreset = (preset: IPreset) => {

        setSelectedPreset(preset);

        let config = currentConfiguration.options;

        for (let i = 0; i < preset.options.length; i++) {
            const element = preset.options[i];

            config = config.filter(e => e.configurable.id !== element.configurableId);

            const configurable = scene.configurables.find(e => e.id == element.configurableId);

            if (configurable) {
                const o = options.find(e => e.id == configurable?.optionsID);
                const option = o?.options.find(e => e.id == element.option);
                const color = option?.colors?.find(e => e.id == element.color);

                if (option) {
                    config.push({ configurable: configurable, option: option, color: color });
                }
            }
        }

        setCurrentConfiguration({ ...currentConfiguration, options: config });
    }

    const changeSelectedPreset = (preset: IPreset | null) => {
        setSelectedPreset(preset);
    }

    const changeShowPreset = (show: boolean) => {
        setShowPreset(show);
    }

    const changeAngle = (angle: string) => {
        setCurrentConfiguration({ ...currentConfiguration, angle: angle });
    }

    const changeOpen = (open: boolean) => {
        setOpen(open);      
    }

    const changeOpenSub = (open: boolean) => {
        setOpenSub(open);
    }

    const changeSelectedConfigurable = (selectedConfigurable: IConfigurableRef | null) => {
        setSelectedConfigurable(selectedConfigurable);
    }

    const changeSelectedOption = (selectedOption: IOption | null) => {
        setSelectedOption(selectedOption);
    }

    return (
        <ConfiguratorContext.Provider
            value={{
                currentConfiguration,
                open,
                openSub,
                showPreset,
                selectedConfigurable,
                selectedOption,
                selectedPreset,
                changeCurrentConfiguration,
                changeOption,
                changeOptions,
                changeAngle,
                changeSelectedConfigurable,
                changeSelectedOption,
                changeSelectedPreset,
                changeOpen,
                changeOpenSub,
                changeShowPreset,
                validatePreset,
                loadPreset
            }}
        >
            {children}
        </ConfiguratorContext.Provider>
    );
};